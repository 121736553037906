
import { defineComponent, ref, onMounted } from "vue"
import { useStore } from "vuex"
import { useRoute, useRouter } from "vue-router"
import { Actions } from "@/store/enums/StoreEnums"
import Swal from "sweetalert2/dist/sweetalert2.min.js"
import WelcomeItem from './WelcomeItem.vue'
import DocumentationIcon from './icons/IconDocumentation.vue'
import ToolingIcon from './icons/IconTooling.vue'
import EcosystemIcon from './icons/IconEcosystem.vue'
import CommunityIcon from './icons/IconCommunity.vue'
import SupportIcon from './icons/IconSupport.vue'

export default defineComponent ({
  setup() {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()

    const eppcms = ref([] as any)
    const current_tab = ref('' as any)
    current_tab.value = 'pending'

    const query = () => {
      store
        .dispatch(Actions.GET_EPPCMS, {status: current_tab.value, eppcm_type: 'countermat'})
        .then(() => {
          eppcms.value = store.getters.currentEppcms
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        });
    }

    query()

    const goToApprovePricePoster = () => {
      router.push({ name: "approve_price_poster" })
    }

    const goToExportGroup = (id) => {
      router.push({ name: "approve_export_group", params: {id: id}})
    }

    const approve = (id) => {
      store
        .dispatch(Actions.APPROVE_EPPCM, {id: id})
        .then(() => {
          Swal.fire({
            text: "审核成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const reject = (id) => {
      store
        .dispatch(Actions.REJECT_EPPCM, {id: id})
        .then(() => {
          Swal.fire({
            text: "驳回成功",
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "确认",
            customClass: {
              confirmButton: "btn fw-bold btn-light-primary",
            },
          })

          query()
        })
        .catch(() => {
          // const [error] = Object.keys(store.getters.getErrors);
          // Swal.fire({
          //   text: store.getters.getErrors[error],
          //   icon: "error",
          //   buttonsStyling: false,
          //   confirmButtonText: "Try again!",
          //   customClass: {
          //     confirmButton: "btn fw-bold btn-light-danger",
          //   },
          // });
        })
    }

    const change_tab = (tab) => {
      current_tab.value = tab
      query()
    }

    return {
      eppcms,
      goToApprovePricePoster,
      approve,
      reject,
      current_tab,
      change_tab,
      goToExportGroup
    }
  }
})
